<template>
  <a-card>
    <div>
      <div class="table-operator">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="12" :sm="24">
              <a-form-item label="行动名称">
                <a-input
                  v-model="queryParam.name"
                  placeholder="请输入"
                  @change="() => {}"
                />
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24" style="text-align: right">
              <span class="table-page-search-submitButtons" :style="{}">
                <a-button
                  style="margin-left: 8px"
                  @click="() => (this.queryParam = {})"
                  >重置
                </a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="add">新建</a-button>
      </div>
      <add-action-modal
        @close="() => (this.createVisible = false)"
        v-bind:visible="createVisible"
      />
      <a-spin :spinning="loading">
        <a-table
          :columns="columns"
          :dataSource="this.pageData"
          rowKey="uuid"
          :pagination="pagination"
          @change="changePageSize"
        >
          <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
          <span slot="actions" slot-scope="text, record" class="blueColor">
            <a @click="edit(record)">编辑</a>&nbsp;&nbsp;
            <a @click="showConfirm(record)">删除</a>
          </span>
        </a-table>
      </a-spin>
    </div>
  </a-card>
</template>

<script>
import { modal } from "ant-design-vue";
import { mapActions, mapGetters } from "vuex";
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
import AddActionModal from "./detail/addActionModal";
import {
  DELETE_ROLE_ACTION,
  GET_ROLE_ACTIONS
} from "../../store/modules/actions.type";

export default {
  name: "actions",
  components: { AddActionModal },
  data() {
    return {
      page: 1,
      pageSize: 20,

      createVisible: false,
      record: null,
      loading: false,

      // 查询参数
      queryParam: {
        name: ""
      },
      // 表头
      columns: [
        {
          title: "行动名称",
          dataIndex: "name"
        },
        {
          title: "行动说明",
          dataIndex: "description"
        },
        {
          title: "行为",
          dataIndex: "action"
        },
        {
          title: "操作",
          dataIndex: "actions",
          width: "300px",
          scopedSlots: { customRender: "actions" }
        }
      ]
    };
  },
  created: async function() {
    this.loading = true;
    if (this.actions.length === 0) {
      await this.getRoleActions();
    }
    this.loading = false;

    Bus.$on("getTarget", () => {
      this.$router.push({
        path: "/authorization/actions"
      });
    });
  },
  computed: {
    ...mapGetters("roles", ["actions"]),
    filteredActions: function() {
      return this.actions.filter(action =>
        action.name.includes(this.queryParam.name || "")
      );
    },
    pageData: function() {
      const start = (this.page - 1) * this.pageSize;
      return this.filteredActions.slice(start, start + this.pageSize);
    },
    pagination: function() {
      return defaultPagination(() => {}, this.filteredActions.length);
    }
  },
  methods: {
    ...mapActions("roles", [GET_ROLE_ACTIONS, DELETE_ROLE_ACTION]),

    changePageSize(val) {
      this.page = val.current;
      this.pageSize = val.pageSize;
    },
    add() {
      this.createVisible = true;
    },
    edit(val) {
      this.$router.push({
        path: "/authorization/actions/" + val.uuid
      });
    },
    showConfirm(val) {
      let that = this;
      modal.confirm({
        title: "确定要删除该角色行动吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk: async function() {
          await that.deleteRoleAction(val.uuid);
        },
        onCancel() {}
      });
    }
  }
};
</script>

<style scoped></style>
