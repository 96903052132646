<template>
  <a-modal
    title="新建角色"
    :width="640"
    :visible="visible"
    :confirmLoading="loading"
    destroyOnClose
    @ok="onSubmit"
    @cancel="onCancel"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item
          label="角色行动名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '请输入' }] }
            ]"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item
          label="角色行动说明"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            v-decorator="[
              'description',
              { rules: [{ required: true, message: '请输入' }] }
            ]"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item
          label="角色行动"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            v-decorator="[
              'action',
              { rules: [{ required: true, message: '请输入' }] }
            ]"
            placeholder="请输入"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_ROLE_ACTION } from "../../../store/modules/actions.type";

export default {
  name: "addActionModal",
  props: ["visible"],

  data() {
    return {
      loading: false,
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    };
  },

  methods: {
    ...mapActions("roles", [CREATE_ROLE_ACTION]),
    onSubmit() {
      const {
        form: { validateFields }
      } = this;

      validateFields(async (errors, values) => {
        if (!errors) {
          this.loading = true;
          await this.createRoleAction({ ...values });
          this.$emit("close");
        }
        this.loading = false;
      });
    },
    onCancel() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
